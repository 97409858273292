var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { MainAppPagePaths } from "../../../../../shared/types/app-events";
import TourialLogoWithName from "../../../../assets/images/v3/tourial-logo-with-name.svg";
import InstallIcon from "../../../../assets/images/v3/white-install-icon.svg";
import { newTheme } from "../../styled/theme";
import styled from "styled-components";
import { useFeatureFlags } from "../../../redux/selectors/redux-selectors";
import { useOutsideClick } from "../../../hooks/use-outside-click";
import { useAccount, useSetWorkspaceId, useUserData, useWorkspaceId } from "../../../legacy/selectors";
import { DropdownContainer, DropdownOption } from "../home-page/tourial-list";
import { isAuthorized } from "../../../../../shared/functions/auth-functions";
import { AccessLevel } from "../../../../../shared/types/auth-types";
import CreateWorkspaceModal from "../home-page/workspace-create-modal";
import { useFetchWorkspaces } from "../../../hooks/use-fetch-workspaces";
import { useQueryParam } from "../../../hooks/use-query-param";
import SideBarLink from "./side-bar-link";
import { SideBarIcon, SideBarIconTypes } from "./side-bar-icon";
import { removeParam } from "../../../../../shared/functions/helpers";
import SideBarWorkspaceItem from "./side-bar-workspace-item";
import { useWorkspaceUserData } from "../../../hooks/use-fetch-current-workspace";
import { HOME_WORKSPACE_PARAM, VIEW_ALL_WORKSPACE_PARAM } from "../../../../../shared/types/account";
import TButton from "../../styled/t-button";
import { useAsync } from "react-async";
import { PAGE_HEADER_Z_INDEX } from "../../../types/definitions";
const SIDEBAR_WIDTH = 264;
export function checkExtensionInstalled() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const chromeExtensionId = process.env.DAR_ENV === "production" ? "bgnjlhcgomfmcohochohgmplhfeipjob" : "hfdfiaoajjfgifhfebgflhggaoobnipm";
            yield fetch(`chrome-extension://${chromeExtensionId}/popup-icon-16.png`);
            return true;
        }
        catch (e) {
            return false;
        }
    });
}
export default function SideBar() {
    var _a;
    const { companyName } = useAccount();
    const { userAccess } = useUserData();
    const [isWorkspaceHovered, setIsWorkspaceHovered] = useState(false);
    const workspaceRef = useRef();
    const history = useHistory();
    const { outboundEmailLinks, workspaces: workspaceFlag, shareLinks, warmly } = useFeatureFlags();
    const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] = useState(false);
    const [isWorkspaceSectionOpen, setIsWorkspaceSectionOpen] = useState(false);
    const { workspaces } = useFetchWorkspaces();
    const setActiveWorkspaceId = useSetWorkspaceId();
    const activeWorkspaceId = useWorkspaceId();
    const activeWorkspaceData = workspaces.find(f => f._id === activeWorkspaceId);
    const reload = useQueryParam("reload-list");
    const [extensionInstalled, setExtensionInstalled] = useState(false);
    useAsync({
        promiseFn: checkExtensionInstalled,
        onResolve: data => setExtensionInstalled(data),
    });
    useEffect(() => {
        if (reload) {
            removeParam(history, "reload-list");
        }
    }, [reload]);
    const projectCount = (!activeWorkspaceId ? (_a = workspaces === null || workspaces === void 0 ? void 0 : workspaces.find(w => !w._id)) === null || _a === void 0 ? void 0 : _a.projectCount : activeWorkspaceData === null || activeWorkspaceData === void 0 ? void 0 : activeWorkspaceData.projectCount) || 0;
    const { userCount } = useWorkspaceUserData(activeWorkspaceData);
    useOutsideClick([workspaceRef], () => {
        if (isWorkspaceSectionOpen)
            setIsWorkspaceSectionOpen(false);
    });
    const workspaceName = !activeWorkspaceId
        ? "Browse workspaces"
        : activeWorkspaceId === VIEW_ALL_WORKSPACE_PARAM
            ? "All tours"
            : activeWorkspaceId === HOME_WORKSPACE_PARAM
                ? `${companyName} Public`
                : activeWorkspaceData === null || activeWorkspaceData === void 0 ? void 0 : activeWorkspaceData.name;
    const workspaceDropdownBottom = 38 + (activeWorkspaceData ? 22 : 0);
    return (React.createElement(SideBarContainer, { className: "_test_side-bar-container" },
        React.createElement(SideBarPageLinksContainer, null,
            React.createElement("img", { style: { width: 141.84, height: 40, marginBottom: 16 }, src: TourialLogoWithName }),
            React.createElement(HomeContainer, { onMouseEnter: () => setIsWorkspaceHovered(true), onMouseLeave: () => setIsWorkspaceHovered(false), isHovered: isWorkspaceHovered },
                React.createElement(WorkspaceNameContainer, { onClick: () => {
                        if (window.location.pathname !== MainAppPagePaths.HOMEPAGE) {
                            history.push(MainAppPagePaths.HOMEPAGE);
                        }
                    } },
                    React.createElement("div", { style: { display: "flex" } },
                        React.createElement(SideBarIcon, { type: SideBarIconTypes.VIEW_ALL, fill: isWorkspaceHovered ? newTheme.colors.blue500 : "black", style: { padding: "0px 12px", margin: 0 } }),
                        React.createElement(WorkspaceName, { className: "text-ellipsis" }, workspaceFlag ? workspaceName : "Home")),
                    activeWorkspaceId && activeWorkspaceId !== VIEW_ALL_WORKSPACE_PARAM && (React.createElement("span", { style: { paddingLeft: 38, font: newTheme.fonts.small, color: newTheme.colors.grey500 } },
                        projectCount,
                        " projects | ",
                        (activeWorkspaceData === null || activeWorkspaceData === void 0 ? void 0 : activeWorkspaceData.isPublic) || !(activeWorkspaceData === null || activeWorkspaceData === void 0 ? void 0 : activeWorkspaceData.name) ? "All members" : userCount + " members"))),
                workspaceFlag && (React.createElement(SideBarIcon, { type: SideBarIconTypes.UP_AND_DOWN_CHEVRONS, fill: isWorkspaceHovered ? newTheme.colors.blue500 : "black", onClick: () => setIsWorkspaceSectionOpen(!isWorkspaceSectionOpen), style: { padding: "4px 12px 0px", alignItems: "start", margin: 0 } }))),
            React.createElement(WorkspaceSectionContainer, { style: { bottom: workspaceDropdownBottom } }, isWorkspaceSectionOpen && workspaceFlag && (React.createElement(WorkspaceDropdownContainer, { ref: workspaceRef },
                isAuthorized(AccessLevel.EDITOR, userAccess) && (React.createElement(DropdownOption, { key: "create-new-workspace", onClick: () => setIsCreateWorkspaceModalOpen(true) },
                    React.createElement(SideBarIcon, { type: SideBarIconTypes.PLUS, style: { cursor: "pointer" }, fill: newTheme.colors.blue500 }),
                    "Create new workspace")),
                React.createElement(DropdownOption, { onClick: () => {
                        if (window.location.pathname !== MainAppPagePaths.HOMEPAGE) {
                            history.push(MainAppPagePaths.HOMEPAGE);
                        }
                        setActiveWorkspaceId("");
                        setIsWorkspaceSectionOpen(false);
                    } },
                    React.createElement(SideBarIcon, { type: SideBarIconTypes.VIEW_ALL_WORKSPACES_OPTION, style: { cursor: "pointer" }, fill: newTheme.colors.blue500 }),
                    "Browse workspaces"),
                React.createElement(DropdownOption, { style: { borderBottom: `2px solid ${newTheme.colors.grey200}` }, onClick: () => {
                        if (window.location.pathname !== MainAppPagePaths.HOMEPAGE) {
                            history.push(MainAppPagePaths.HOMEPAGE);
                        }
                        setActiveWorkspaceId(VIEW_ALL_WORKSPACE_PARAM);
                        setIsWorkspaceSectionOpen(false);
                    } },
                    React.createElement(SideBarIcon, { type: SideBarIconTypes.VIEW_ALL_OPTION, style: { cursor: "pointer" }, fill: newTheme.colors.blue500 }),
                    "All tours"),
                workspaces.map(workspace => {
                    return (React.createElement(SideBarWorkspaceItem, { key: workspace._id, workspace: workspace, setIsWorkspaceSectionOpen: setIsWorkspaceSectionOpen }));
                })))),
            shareLinks && (React.createElement(SideBarLink, { icon: SideBarIconTypes.LINK_ACTIVITY, to: `${MainAppPagePaths.LINK_ACTIVITY}`, label: "Share Links" })),
            warmly && (React.createElement(SideBarLink, { icon: SideBarIconTypes.REVEAL, to: `${MainAppPagePaths.WARMLYIFRAME}`, label: "Reveal" })),
            React.createElement(SideBarLink, { icon: SideBarIconTypes.ANALYTICS, to: `${MainAppPagePaths.ANALYTICS}`, label: "Analytics" }),
            outboundEmailLinks && (React.createElement(SideBarLink, { icon: SideBarIconTypes.ANALYTICS, to: `${MainAppPagePaths.ANALYTICS_OUTBOUND}`, label: "Outbound Activity" }))),
        React.createElement(SideBarUserLinksContainer, null,
            !extensionInstalled && (React.createElement(ChromeExtensionButton, null,
                React.createElement(LogoContainer, null,
                    React.createElement("img", { src: "https://res.cloudinary.com/dim9has1z/image/upload/v1710198751/yxwrgefuowcgiwtsvjet.png" })),
                React.createElement("div", { style: { display: "flex", flexDirection: "column", textAlign: "center" } },
                    React.createElement("div", { style: { font: newTheme.fonts.mediumBold, color: newTheme.colors.black } }, "Add the Chrome Extension"),
                    React.createElement("div", { style: { font: newTheme.fonts.small, color: newTheme.colors.grey700, lineHeight: "16px" } }, "Capture a demo in just minutes with the Tourial extension!")),
                React.createElement(TButton, { style: { width: "100%" }, size: "small", onClick: () => {
                        window.open("https://chrome.google.com/webstore/detail/tourial-extension/bgnjlhcgomfmcohochohgmplhfeipjob/related?hl=en&authuser=1", "_blank");
                    }, iconPlacement: "right", icon: InstallIcon }, "Install"))),
            React.createElement(SideBarLink, { iconStyle: { margin: "0px 6px" }, icon: SideBarIconTypes.FORMS, to: `${MainAppPagePaths.FORMS}`, label: "Forms" }),
            React.createElement(SideBarLink, { icon: SideBarIconTypes.INTEGRATIONS, to: `${MainAppPagePaths.INTEGRATIONS}`, label: "Integrations" }),
            isAuthorized(AccessLevel.ADMIN, userAccess) && (React.createElement(SideBarLink, { icon: SideBarIconTypes.MANAGE_USERS, to: `${MainAppPagePaths.USER_MANAGEMENT}`, label: "Manage Users" }))),
        isCreateWorkspaceModalOpen && (React.createElement(CreateWorkspaceModal, { setIsCreateWorkspaceModalOpen: setIsCreateWorkspaceModalOpen }))));
}
const HomeContainer = styled.div `
  width: calc(100% + 32px);
  margin-left: -16px;
  font: ${newTheme.fonts.medium};
  display: flex;
  cursor: pointer;
  color: ${({ isHovered }) => (isHovered ? newTheme.colors.blue500 : newTheme.colors.grey700)};
`;
const WorkspaceDropdownContainer = styled(DropdownContainer) `
  width: 223px;
  max-height: 714px;
  overflow-y: scroll;
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`;
const HelpSupportContainer = styled.div `
  position: relative;
  z-index: 1;
  width: 0px;
  height: 0px;
  overflow: visible;
  bottom: ${({ extensionInstalled }) => (extensionInstalled ? 128 : 72) - 28}px;
  left: ${SIDEBAR_WIDTH + 4}px;
  color: ${newTheme.colors.grey700};
`;
const WorkspaceSectionContainer = styled(HelpSupportContainer) `
  left: 252px;
`;
const SideBarContainer = styled.div `
  height: 100%;
  width: ${SIDEBAR_WIDTH}px;
  min-width: ${SIDEBAR_WIDTH}px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow:
    0px 0px 1px rgba(48, 49, 51, 0.05),
    2px 0px 4px rgba(48, 49, 51, 0.1);
  z-index: ${PAGE_HEADER_Z_INDEX + 1};
`;
const SideBarPageLinksContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  color: ${newTheme.colors.grey700};
`;
const SideBarUserLinksContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;
const ChromeExtensionButton = styled.div `
  border-radius: 8px;
  border: 1px solid #94bbf8;
  width: 232px;
  height: 176px;
  background-image: url(https://res.cloudinary.com/dim9has1z/image/upload/v1710198061/ajmd6d1e4vkojf15hpii.png);
  padding: 17px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;
const LogoContainer = styled.div `
  border-radius: 4px;
  border: 1px solid rgba(26, 115, 232, 0.1);
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;
const WorkspaceName = styled.span `
  width: 193px;
`;
const WorkspaceNameContainer = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`;
