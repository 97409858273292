import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AuthenticatedRoute, GeneralRoute, UnauthenticatedRoute } from "./custom-routes";
const Homepage = lazy(() => import("../pages/home-page/home-page"));
// const GetStarted = lazy(() => import("../pages/get-started/get-started"));
import Login from "../../legacy/components/login/login-page";
const Admin = lazy(() => import("../../legacy/components/admin/admin-page"));
const DomainSetup = lazy(() => import("../../legacy/components/admin/domain-setup-page"));
const CustomScripts = lazy(() => import("../../legacy/components/admin/custom-scripts/admin-custom-scripts-page"));
const SuperAdmin = lazy(() => import("../../legacy/components/admin/super-admin/super-admin-page"));
const AdminAccounts = lazy(() => import("../../legacy/components/admin/super-admin/accounts/super-admin-accounts-page"));
const ProfilePage = lazy(() => import("../pages/user-management/profile"));
const OutboundAnalytics = lazy(() => import("../pages/analytics/outbound"));
const ActivityList = lazy(() => import("../../legacy/components/admin/super-admin/super-admin-activity-page"));
const Jobs = lazy(() => import("../../legacy/components/admin/super-admin/super-admin-jobs-page"));
const AdminInsights = lazy(() => import("../../legacy/components/admin/super-admin/super-admin-insights-page"));
const FormEditorPage = lazy(() => import("../pages/forms/form-editor-page"));
const FormListPage = lazy(() => import("../pages/forms/form-list-page"));
const MicrositesEditorPage = lazy(() => import("../pages/microsites/microsite-editor-page"));
const BrandingPage = lazy(() => import("../pages/branding/branding-page"));
const BrandingEditor = lazy(() => import("../pages/branding/editor/branding-editor"));
const IntegrationsV2 = lazy(() => import("../pages/integrations/integrations"));
const HubspotIntegrationsPage = lazy(() => import("../pages/integrations/hubspot/hubspot-integrations-page"));
const SalesforceIntegrationsPage = lazy(() => import("../pages/integrations/salesforce/salesforce-integrations-page"));
const SlackIntegrationsPage = lazy(() => import("../pages/integrations/slack/slack-integrations-page"));
const LegacyBuilderPage = lazy(() => import("../../legacy/components/builder/builder-page"));
const MarketoInstall = lazy(() => import("../pages/integrations/marketo/marketo-install"));
const MarketoIntegrationsPage = lazy(() => import("../pages/integrations/marketo/marketo-integrations-page"));
const BuilderPage = lazy(() => import("../pages/builder/builder-page"));
const PardotIntegrationsPage = lazy(() => import("../pages/integrations/pardot/pardot-integrations-page"));
const PardotInstall = lazy(() => import("../pages/integrations/pardot/pardot-install"));
const CalendlyIntegrationsPage = lazy(() => import("../pages/integrations/calendly/calendly-integrations-page"));
const ChiliPiperIntegrationsPage = lazy(() => import("../pages/integrations/chilipiper/chilipiper-integrations-page"));
const ZapierIntegrationsPage = lazy(() => import("../pages/integrations/zapier/zapier-integrations-page"));
const UserManagementPage = lazy(() => import("../pages/user-management/user-management-page"));
const AnalyticsPage = lazy(() => import("../pages/analytics/analytics-page"));
const DCBuilder = lazy(() => import("../pages/demo-center/builder/dc-builder"));
const AdminAccountSelector = lazy(() => import("../pages/admin-account-selector-widget"));
const LinkActivityPage = lazy(() => import("../pages/link-activity/link-activity"));
const WarmlyIframePage = lazy(() => import("../pages/integrations/warmly-iframes"));
import { MainAppPagePaths, PAGE_TITLES } from "../../../../shared/types/app-events";
import SideBar from "../pages/sidebar/side-bar";
import { Four04Page } from "../pages/404-page";
import styled from "styled-components";
import { newTheme } from "../styled/theme";
import { AccessLevel } from "../../../../shared/types/auth-types";
import { TLoadingSpinner } from "../styled/t-loading-spinner";
import { BrandingType } from "../../../../shared/types/theme";
import PageHeader from "../shared/page-header";
function PageWrapper({ children, path }) {
    return (React.createElement(BaseDiv, null,
        React.createElement(SideBar, null),
        React.createElement(MainDiv, { id: "tourial-page-content" },
            React.createElement(PageHeader, { title: PAGE_TITLES[path] || "Tourial" }),
            children),
        React.createElement(AdminAccountSelector, null)));
}
const brandingEditors = [
    { path: MainAppPagePaths.BRANDING_EDITOR_TOOLTIP, type: BrandingType.TOOLTIP },
    { path: MainAppPagePaths.BRANDING_EDITOR_TYPEWRITER, type: BrandingType.TYPEWRITER },
    { path: MainAppPagePaths.BRANDING_EDITOR_MOUSE, type: BrandingType.MOUSE },
    { path: MainAppPagePaths.BRANDING_EDITOR_MODAL, type: BrandingType.MODAL },
    { path: MainAppPagePaths.BRANDING_EDITOR_OVERLAY, type: BrandingType.OVERLAY },
    { path: MainAppPagePaths.BRANDING_EDITOR_NAV, type: BrandingType.NAV },
    { path: MainAppPagePaths.BRANDING_EDITOR_PAGE, type: BrandingType.PAGE },
    { path: MainAppPagePaths.BRANDING_EDITOR_FORM, type: BrandingType.FORM },
];
export function AppRouter() {
    return (React.createElement(Suspense, { fallback: React.createElement(BaseDiv, null,
            React.createElement(TLoadingSpinner, null)) },
        React.createElement(Switch, null,
            React.createElement(UnauthenticatedRoute, { exact: true, path: MainAppPagePaths.LOGIN, component: () => (React.createElement("div", null,
                    React.createElement(Login, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.HOMEPAGE, authorization: AccessLevel.VIEWER, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.HOMEPAGE },
                    React.createElement(Homepage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.LINK_ACTIVITY, authorization: AccessLevel.VIEWER, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.LINK_ACTIVITY },
                    React.createElement(LinkActivityPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.ANALYTICS, authorization: AccessLevel.VIEWER, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.ANALYTICS },
                    React.createElement(AnalyticsPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.ANALYTICS_OUTBOUND, authorization: AccessLevel.VIEWER, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.ANALYTICS_OUTBOUND },
                    React.createElement(OutboundAnalytics, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_MARKETO_SETUP, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_MARKETO_SETUP },
                    React.createElement(MarketoInstall, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_MARKETO, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_MARKETO },
                    React.createElement(MarketoIntegrationsPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_HUBSPOT, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_HUBSPOT },
                    React.createElement(HubspotIntegrationsPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_SALESFORCE, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_SALESFORCE },
                    React.createElement(SalesforceIntegrationsPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_SLACK, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_SLACK },
                    React.createElement(SlackIntegrationsPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_PARDOT, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_PARDOT },
                    React.createElement(PardotIntegrationsPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_PARDOT_SETUP, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_PARDOT_SETUP },
                    React.createElement(PardotInstall, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_CALENDLY, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_CALENDLY },
                    React.createElement(CalendlyIntegrationsPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_CHILIPIPER, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_CHILIPIPER },
                    React.createElement(ChiliPiperIntegrationsPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS_ZAPIER, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS_ZAPIER },
                    React.createElement(ZapierIntegrationsPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.INTEGRATIONS, authorization: AccessLevel.VIEWER, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.INTEGRATIONS },
                    React.createElement(IntegrationsV2, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.ADMIN, authorization: AccessLevel.SUPERADMIN, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.ADMIN },
                    React.createElement(SuperAdmin, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.ADMIN_ACCOUNTS, authorization: AccessLevel.SUPERADMIN, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.ADMIN_ACCOUNTS },
                    React.createElement(AdminAccounts, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.ADMIN_JOBS, authorization: AccessLevel.SUPERADMIN, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.ADMIN_JOBS },
                    React.createElement(Jobs, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.ADMIN_INSIGHTS, authorization: AccessLevel.SUPERADMIN, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.ADMIN_INSIGHTS },
                    React.createElement(AdminInsights, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.ADMIN_ACTIVITY, authorization: AccessLevel.SUPERADMIN, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.ADMIN_ACTIVITY },
                    React.createElement(ActivityList, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.SETTINGS, authorization: AccessLevel.ADMIN, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.SETTINGS },
                    React.createElement(Admin, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.SETTINGS_DOMAIN, authorization: AccessLevel.ADMIN, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.SETTINGS_DOMAIN },
                    React.createElement(DomainSetup, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.SETTINGS_SCRIPTS, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.SETTINGS_SCRIPTS },
                    React.createElement(CustomScripts, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.PROFILE, authorization: AccessLevel.VIEWER, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.PROFILE },
                    React.createElement(ProfilePage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.FORMS, authorization: AccessLevel.VIEWER, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.FORMS },
                    React.createElement(FormListPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.FORM_EDITOR, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.FORM_EDITOR },
                    React.createElement(FormEditorPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.BRANDING, authorization: AccessLevel.VIEWER, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.BRANDING },
                    React.createElement(BrandingPage, null))) }),
            brandingEditors.map(be => (React.createElement(AuthenticatedRoute, { key: be.type, exact: true, path: be.path, authorization: AccessLevel.EDITOR, component: () => (React.createElement(PageWrapper, { path: be.path },
                    React.createElement(BrandingEditor, { editorType: be.type }))) }))),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.USER_MANAGEMENT, authorization: AccessLevel.ADMIN, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.USER_MANAGEMENT },
                    React.createElement(UserManagementPage, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.MICROSITES_EDITOR, authorization: AccessLevel.VIEWER, component: () => (React.createElement(React.Fragment, null,
                    React.createElement(MicrositesEditorPage, null),
                    React.createElement(AdminAccountSelector, null))) }),
            React.createElement(AuthenticatedRoute, { exact: false, path: `${MainAppPagePaths.DEMOCENTER_BUILDER}/:id`, authorization: AccessLevel.VIEWER, component: () => (React.createElement(React.Fragment, null,
                    React.createElement(DCBuilder, null),
                    React.createElement(AdminAccountSelector, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.BUILDER, authorization: AccessLevel.EDITOR, component: () => (React.createElement(React.Fragment, null,
                    React.createElement(LegacyBuilderPage, null),
                    React.createElement(AdminAccountSelector, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.V3_BUILDER, authorization: AccessLevel.VIEWER, component: () => (React.createElement(React.Fragment, null,
                    React.createElement(BuilderPage, null),
                    React.createElement(AdminAccountSelector, null))) }),
            React.createElement(AuthenticatedRoute, { exact: true, path: MainAppPagePaths.WARMLYIFRAME, authorization: AccessLevel.VIEWER, component: () => (React.createElement(PageWrapper, { path: MainAppPagePaths.WARMLYIFRAME },
                    React.createElement(WarmlyIframePage, null))) }),
            React.createElement(Route, { path: "*" },
                React.createElement(RedirectHandler, null)),
            React.createElement(GeneralRoute, { path: "*", component: () => React.createElement(Four04Page, null) }))));
}
const RedirectHandler = () => {
    const location = useLocation();
    const RedirectMap = {
        [MainAppPagePaths.MICROSITES_EDITOR_LEGACY]: MainAppPagePaths.MICROSITES_EDITOR,
    };
    const newPath = RedirectMap[location.pathname];
    // Forward the query params if any exist
    const fullPath = newPath ? `${newPath}${location.search}` : null;
    return fullPath ? React.createElement(Redirect, { to: fullPath }) : null;
};
export const BaseDiv = styled.div `
  display: flex;
  background-color: ${newTheme.colors.grey100};
  height: 100vh;
  width: 100vw;
  overflow-y: overlay;
`;
export const MainDiv = styled.div `
  background-color: ${newTheme.colors.white};
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;
