import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useAccount, useSetWorkspaceId, useUserData, useWorkspaceId } from "../../legacy/selectors";
import { newTheme } from "../styled/theme";
import { DropdownContainer, DropdownOption } from "../pages/home-page/tourial-list";
import { SideBarIcon, SideBarIconTypes } from "../pages/sidebar/side-bar-icon";
import { useHistory } from "react-router-dom";
import { isAuthorized } from "../../../../shared/functions/auth-functions";
import { AccessLevel } from "../../../../shared/types/auth-types";
import { MainAppPagePaths, PAGE_TITLES, TourialAppEventTypes, brandingPrefix, integrationsPrefix, } from "../../../../shared/types/app-events";
import { useOutsideClick } from "../../hooks/use-outside-click";
import ProjectRoomIcon from "../../../assets/images/v3/project-room-black.svg";
import HelpIcon from "../../../assets/images/v3/question-mark-circle-black.svg";
import ArrowLeft from "../../../assets/images/v3/arrow-left-grey.svg";
import PublicIconBlue from "../../../assets/images/v3/public-workspace-blue.svg";
import PrivateIconBlue from "../../../assets/images/v3/private-workspace-blue.svg";
import GearIcon from "../../../assets/images/v3/gear-outline.svg";
import { useAsync } from "react-async";
import { checkExtensionInstalled } from "../pages/sidebar/side-bar";
import { useFetchTheme } from "../../hooks/use-fetch-theme";
import tinycolor from "tinycolor2";
import { TBreadcrumbType, TBreadcrumbs } from "../styled/t-breadcrumbs";
import { useFeatureFlags, useUserAccess } from "../../redux/selectors/redux-selectors";
import { BrandingType } from "../../../../shared/types/theme";
import { useQueryParam } from "../../hooks/use-query-param";
import { ensureHttpsInUrl, openUrlInNewTab, removeParam } from "../../../../shared/functions/helpers";
import TButton, { TButtonVariants } from "../styled/t-button";
import EditOrDeleteWorkspace from "../pages/home-page/workspace-edit-modal";
import DeleteWorkspace from "../pages/home-page/workspace-delete-modal";
import { HOME_WORKSPACE_PARAM, VIEW_ALL_WORKSPACE_PARAM } from "../../../../shared/types/account";
import { useFetchWorkspaces } from "../../hooks/use-fetch-workspaces";
import { PAGE_HEADER_HEIGHT, PAGE_HEADER_Z_INDEX } from "../../types/definitions";
import { usePostAppEvent } from "../../hooks/use-post-app-event";
const SETTINGS_BUTTON_WIDTH = 184;
const HELP_BUTTON_WIDTH = 74;
const PROJECT_ROOM_BUTTON_WIDTH = 128;
const DROPDOWN_WIDTH = 223;
export default function PageHeader({ title, style }) {
    const { automation, htmlTours } = useFeatureFlags();
    const selectedId = useQueryParam("id");
    const history = useHistory();
    const { projectRoomLink } = useAccount();
    const postAppEvent = usePostAppEvent();
    const isBrandingEditor = title !== brandingPrefix && title.includes(brandingPrefix);
    const isLinkActivityById = selectedId && title === PAGE_TITLES[MainAppPagePaths.LINK_ACTIVITY];
    const isIntegrationsPage = title !== integrationsPrefix && title.includes(integrationsPrefix);
    const isHomePage = title === PAGE_TITLES[MainAppPagePaths.HOMEPAGE];
    return (React.createElement(PageHeaderContainer, { style: style },
        isBrandingEditor && (React.createElement("div", { style: { marginRight: "auto" } },
            React.createElement(TBreadcrumbs, { items: [
                    { type: TBreadcrumbType.LINK, name: "Branding", to: MainAppPagePaths.BRANDING },
                    {
                        type: TBreadcrumbType.DROPDOWN,
                        selected: title === brandingPrefix ? title : title.split(brandingPrefix)[1],
                        options: [
                            { name: BrandingType.TOOLTIP, to: MainAppPagePaths.BRANDING_EDITOR_TOOLTIP },
                            { name: BrandingType.TYPEWRITER, to: MainAppPagePaths.BRANDING_EDITOR_TYPEWRITER },
                            { name: BrandingType.MOUSE, to: MainAppPagePaths.BRANDING_EDITOR_MOUSE },
                            { name: BrandingType.MODAL, to: MainAppPagePaths.BRANDING_EDITOR_MODAL },
                            { name: BrandingType.OVERLAY, to: MainAppPagePaths.BRANDING_EDITOR_OVERLAY },
                            { name: BrandingType.NAV, to: MainAppPagePaths.BRANDING_EDITOR_NAV },
                            { name: BrandingType.PAGE, to: MainAppPagePaths.BRANDING_EDITOR_PAGE },
                            { name: BrandingType.FORM, to: MainAppPagePaths.BRANDING_EDITOR_FORM },
                        ]
                            .filter(b => automation || (b.name !== BrandingType.MOUSE && b.name !== BrandingType.TYPEWRITER))
                            .filter(b => htmlTours || b.name !== BrandingType.OVERLAY),
                    },
                ] }))),
        isLinkActivityById && (React.createElement(BackButton, { onClick: () => {
                removeParam(history, "id");
            } },
            React.createElement("img", { src: ArrowLeft }),
            "Back to all links")),
        isIntegrationsPage && (React.createElement(BackButton, { onClick: () => {
                history.push(MainAppPagePaths.INTEGRATIONS);
            } },
            React.createElement("img", { src: ArrowLeft }),
            "Back to integrations")),
        isHomePage && React.createElement(HomepageHeader, null),
        !isHomePage && !isIntegrationsPage && !isLinkActivityById && !isBrandingEditor && React.createElement(Title, null, title),
        projectRoomLink && (React.createElement(ProjectRoomButton, { isActive: false, onClick: () => {
                postAppEvent(TourialAppEventTypes.PROJECT_ROOM_LINK_CLICKED, { projectRoomLink });
                openUrlInNewTab(ensureHttpsInUrl(projectRoomLink));
            } },
            React.createElement("img", { src: ProjectRoomIcon }),
            React.createElement("span", { style: { color: newTheme.colors.grey500, whiteSpace: "nowrap" } }, "Project Room"))),
        React.createElement(HelpDropdown, null),
        React.createElement(SettingsDropdown, null)));
}
function HomepageHeader() {
    const { workspaces: workspacesFF } = useFeatureFlags();
    const userAccess = useUserAccess();
    const { companyName } = useAccount();
    const [isEditWorkspaceOpen, setIsEditWorkspaceOpen] = useState(false);
    const [isDeleteWorkspaceOpen, setIsDeleteWorkspaceOpen] = useState(false);
    const setWorkspaceId = useSetWorkspaceId();
    const { workspaces } = useFetchWorkspaces();
    const activeWorkspaceId = useWorkspaceId();
    const isHomeWorkspace = activeWorkspaceId === HOME_WORKSPACE_PARAM; // aka `{COMPANY_NAME} Public`
    const isViewAll = activeWorkspaceId === VIEW_ALL_WORKSPACE_PARAM;
    const currentWorkspace = workspaces.find(f => !isHomeWorkspace && !isViewAll && f._id === activeWorkspaceId);
    const { name: currentWorkspaceName, _id: currentWorspaceId } = currentWorkspace || {};
    return (React.createElement(HomePageHeaderContainer, null,
        workspacesFF && activeWorkspaceId && (React.createElement(React.Fragment, null,
            React.createElement(WorkspaceBreadcrumb, { onClick: () => setWorkspaceId("") }, "Workspaces"),
            React.createElement("div", { style: { color: newTheme.colors.grey500 } }, "/"))),
        React.createElement("div", { className: "text-ellipsis" }, workspacesFF
            ? !activeWorkspaceId
                ? "Workspaces"
                : `${currentWorkspaceName || (isHomeWorkspace ? `${companyName} Public` : "All Tours")}`
            : "Home"),
        activeWorkspaceId && !isViewAll && (React.createElement(WorkspaceStatus, null,
            React.createElement("img", { src: isHomeWorkspace || (currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.isPublic) ? PublicIconBlue : PrivateIconBlue }),
            React.createElement("div", null, isHomeWorkspace || (currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.isPublic) ? "Public workspace" : "Invite-only workspace"))),
        currentWorspaceId && isAuthorized(AccessLevel.ADMIN, userAccess) && (React.createElement(TButton, { onClick: () => setIsEditWorkspaceOpen(true), size: "medium", variant: TButtonVariants.OUTLINE, icon: GearIcon, style: { width: 36 } })),
        isEditWorkspaceOpen && (React.createElement(EditOrDeleteWorkspace, { workspace: currentWorkspace, onClose: () => setIsEditWorkspaceOpen(false), onDelete: () => setIsDeleteWorkspaceOpen(true) })),
        isDeleteWorkspaceOpen && (React.createElement(DeleteWorkspace, { workspace: currentWorkspace, onClose: () => {
                setIsDeleteWorkspaceOpen(false);
                setWorkspaceId("");
            } }))));
}
function HelpDropdown() {
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [extensionInstalled, setExtensionInstalled] = useState(false);
    useAsync({
        promiseFn: checkExtensionInstalled,
        onResolve: data => setExtensionInstalled(data),
    });
    const helpRef = useRef();
    useOutsideClick([helpRef], () => {
        if (isHelpOpen)
            setIsHelpOpen(false);
    });
    return (React.createElement("div", null,
        React.createElement(HelpDropdownButton, { isActive: isHelpOpen, onClick: () => setIsHelpOpen(!isHelpOpen) },
            React.createElement("img", { src: HelpIcon }),
            React.createElement("span", { style: { color: newTheme.colors.grey500 } }, "Help")),
        React.createElement(HelpContainer, null, isHelpOpen && (React.createElement(SideBarDropdownContainer, { ref: helpRef },
            React.createElement(DropdownOption, { style: { display: "flex", justifyContent: "space-between" }, key: "Contact Support", onClick: () => window.open("https://form.asana.com/?k=MGdVhbKun_OG92XX0mAytA&d=1169712465458242", "_blank") },
                React.createElement("div", { style: { margin: "8px 8px 8px 16px" } }, "Contact Support"),
                React.createElement(SideBarIcon, { type: SideBarIconTypes.SUPPORT_LINK, style: { cursor: "pointer" }, fill: newTheme.colors.blue500 })),
            React.createElement(DropdownOption, { style: { display: "flex", justifyContent: "space-between" }, key: "Knowledge Base", onClick: () => window.open("https://tourial.gitbook.io/tourial-customer-product-documentation/", "_blank") },
                React.createElement("div", { style: { margin: "8px 8px 8px 16px" } }, "Knowledge Base"),
                React.createElement(SideBarIcon, { type: SideBarIconTypes.SUPPORT_LINK, style: { cursor: "pointer" }, fill: newTheme.colors.blue500 })),
            extensionInstalled && (React.createElement(DropdownOption, { style: { display: "flex", justifyContent: "space-between", height: "fit-content" }, key: "Download Chrome Extension", onClick: () => window.open("https://chrome.google.com/webstore/detail/tourial-extension/bgnjlhcgomfmcohochohgmplhfeipjob/related?hl=en&authuser=1", "_blank") },
                React.createElement("div", { style: { margin: "8px 8px 8px 16px" } }, "Download Chrome Extension"),
                React.createElement(SideBarIcon, { type: SideBarIconTypes.SUPPORT_LINK, style: { cursor: "pointer" }, fill: newTheme.colors.blue500 }))),
            React.createElement(DropdownOption, { style: { display: "flex", justifyContent: "space-between", height: "fit-content" }, key: "Sign up for Group Training", onClick: () => window.open("https://form.asana.com/?k=wJ0JbmUDHHkMzq3hfdV_6w&d=1169712465458242", "_blank") },
                React.createElement("div", { style: { margin: "8px 8px 8px 16px" } }, "Sign up for Group Training"),
                React.createElement(SideBarIcon, { type: SideBarIconTypes.SUPPORT_LINK, style: { cursor: "pointer" }, fill: newTheme.colors.blue500 })))))));
}
function SettingsDropdown() {
    const { email, userAccess, loggedInAccount } = useUserData();
    const { theme } = useFetchTheme();
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const history = useHistory();
    const signOut = () => {
        window.location.href = "/api/v2/auth/logout";
    };
    const backgroundColor = tinycolor(theme.colors.primary).isLight()
        ? tinycolor(theme.colors.primary).desaturate(10).darken(30).toHexString()
        : tinycolor(theme.colors.primary).saturate(10).lighten(30).toHexString();
    const settingsOptions = [
        {
            label: "Branding",
            icon: SideBarIconTypes.BRANDING,
            onClick: () => history.push(`${MainAppPagePaths.BRANDING}`),
        },
        ...(isAuthorized(AccessLevel.EDITOR, userAccess)
            ? [
                {
                    label: "Custom Scripts",
                    icon: SideBarIconTypes.CUSTOM_SCRIPTS,
                    onClick: () => history.push(`${MainAppPagePaths.SETTINGS_SCRIPTS}`),
                },
            ]
            : []),
        ...(isAuthorized(AccessLevel.ADMIN, userAccess)
            ? [
                {
                    label: "Domain Setup",
                    icon: SideBarIconTypes.DOMAIN_SETUP,
                    onClick: () => history.push(`${MainAppPagePaths.SETTINGS_DOMAIN}`),
                },
            ]
            : []),
        {
            label: "Profile",
            icon: SideBarIconTypes.PROFILE,
            onClick: () => history.push(`${MainAppPagePaths.PROFILE}`),
        },
        ...(isAuthorized(AccessLevel.SUPERADMIN, userAccess)
            ? [
                {
                    label: "Super Admin",
                    icon: SideBarIconTypes.SUPERADMIN,
                    onClick: () => history.push(`${MainAppPagePaths.ADMIN}`),
                },
            ]
            : []),
        {
            label: "Logout",
            icon: SideBarIconTypes.LOGOUT,
            onClick: () => signOut(),
        },
    ];
    const settingsRef = useRef();
    useOutsideClick([settingsRef], () => {
        if (isSettingsOpen)
            setIsSettingsOpen(false);
    });
    return (React.createElement("div", null,
        React.createElement(SettingsDropdownButton, { className: "_test_settings-button", isActive: isSettingsOpen, onClick: () => setIsSettingsOpen(!isSettingsOpen) },
            React.createElement(UserCircle, { style: { backgroundColor, color: theme.colors.primary } }, email.charAt(0).toUpperCase()),
            React.createElement(AccountAndEmail, null,
                React.createElement("span", { className: "text-ellipsis" }, loggedInAccount),
                React.createElement("span", { className: "text-ellipsis", style: { font: newTheme.fonts.extraSmall, color: newTheme.colors.grey500 } }, email)),
            React.createElement(SideBarIcon, { type: SideBarIconTypes.RIGHT_CHEVRON, fill: newTheme.colors.grey700, style: { transform: `rotate(${isSettingsOpen ? -90 : 90}deg)`, margin: 0, height: 16 } })),
        React.createElement(SettingsContainer, null, isSettingsOpen && (React.createElement(SideBarDropdownContainer, { ref: settingsRef, className: "_test_settings-menu" }, settingsOptions.map(option => {
            const { label, onClick, icon, style } = option;
            return (React.createElement(DropdownOption, { key: label, onClick: onClick },
                React.createElement(SideBarIcon, { type: icon, style: Object.assign({ cursor: "pointer" }, style), fill: "black" }),
                label));
        }))))));
}
const WorkspaceBreadcrumb = styled.div `
  border-bottom: 1px solid ${newTheme.colors.grey500};
  color: ${newTheme.colors.grey500};
  cursor: pointer;
`;
const BackButton = styled.div `
  color: ${newTheme.colors.grey500};
  font: ${newTheme.fonts.medium};
  text-decoration-line: underline;
  cursor: pointer;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 4px;
`;
const AccountAndEmail = styled.div `
  display: flex;
  flex-direction: column;
  width: 102px;
  font: ${newTheme.fonts.small};
`;
const UserCircle = styled.div `
  width: 32px;
  height: 32px;
  border: 1px solid ${newTheme.colors.grey300};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: ${newTheme.fonts.normal};
  line-height: 32px;
`;
const PageHeaderContainer = styled.div `
  z-index: ${PAGE_HEADER_Z_INDEX};
  height: ${PAGE_HEADER_HEIGHT}px;
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 0px 12px 0px 32px;
  align-items: center;
  border-bottom: 1px solid ${newTheme.colors.grey300};
  position: sticky;
  top: 0px;
  background: white;
  font: ${newTheme.fonts.medium};
`;
const Title = styled.div `
  margin-right: auto;
  font: ${newTheme.fonts.headerBold};
`;
const SettingsDropdownButton = styled.div `
  cursor: pointer;
  display: flex;
  height: 42px;
  width: ${SETTINGS_BUTTON_WIDTH}px;
  align-items: center;
  gap: 8px;
  padding: 6px 12px 6px 8px;
  &:hover {
    border-radius: 20px;
    background: ${newTheme.colors.grey100};
    box-shadow: 0px 0px 1px 0px rgba(33, 34, 41, 0.4) inset;
  }
  ${({ isActive }) => isActive
    ? `
border-radius: 20px;
background: ${newTheme.colors.grey100};
box-shadow: 0px 0px 1px 0px rgba(33, 34, 41, 0.40) inset;
`
    : ""}
`;
const HelpDropdownButton = styled(SettingsDropdownButton) `
  width: ${HELP_BUTTON_WIDTH}px;
  padding: 4px 8px;
  gap: 4px;
`;
const ProjectRoomButton = styled(SettingsDropdownButton) `
  width: ${PROJECT_ROOM_BUTTON_WIDTH}px;
  padding: 4px 8px;
  gap: 4px;
`;
const SideBarDropdownContainer = styled(DropdownContainer) `
  width: ${DROPDOWN_WIDTH}px;
`;
const SettingsContainer = styled.div `
  position: relative;
  z-index: 1;
  width: 0px;
  height: 0px;
  overflow: visible;
  color: ${newTheme.colors.grey700};
  top: 16px;
  right: ${DROPDOWN_WIDTH - SETTINGS_BUTTON_WIDTH}px;
`;
const HelpContainer = styled(SettingsContainer) `
  right: ${DROPDOWN_WIDTH - HELP_BUTTON_WIDTH}px;
`;
const HomePageHeaderContainer = styled.div `
  display: flex;
  gap: 8px;
  margin-right: auto;
  height: 100%;
  align-items: center;
  font: ${newTheme.fonts.headerBold};
  overflow: hidden;
`;
const WorkspaceStatus = styled.div `
  height: 24px;
  min-width: fit-content;
  display: flex;
  gap: 4px;
  padding: 8px;
  border-radius: 32px;
  background: ${newTheme.colors.blue50};
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.blue500};
  line-height: 24px;
  align-items: center;
  margin-top: 3px;
`;
