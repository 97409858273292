import React, { useEffect, useRef, useState } from "react";
import { useActiveVariantName, useActiveView, useActiveZoom, usePreviousView, usePreviousZoom, useScreen, useScreenMedia, useSetInitialTransitionAndAutoStartState, useSetIsTransitioning, useSetShouldAutoStart, useSetVideoDuration, useSetVideoProgress, useShouldAutoStart, useTourialMedia, useViewportIsTransitioning, } from "../../../redux/selectors/redux-selectors";
import { WrapperChild } from "../../renderer/wrapper/wrapper-child";
import { isImageFileExtension } from "../../../helpers/media";
import styled from "styled-components";
import useVideoPlayer from "../../../hooks/use-video-player";
import VideoControls from "../../pages/builder/editor/atomic/video-controls";
import { getDimensionToGrow, getPrevSrcData, getScreenTransform, } from "../../../helpers/tourial-helpers/tourial-viewport-helpers";
import { modifyVideoFormatForSafari } from "../../../helpers/misc";
import { getVideoThumbnail, transformImageToWebp } from "../../../../../shared/functions/helpers";
import { newTheme } from "../../styled/theme";
import { useAutoJump } from "../../../redux/selectors/interaction-selectors";
import { useSetScreenDidLoad, useTrimTimers } from "../../../redux/selectors/builder-selectors";
import { usePageForm } from "../../../redux/selectors/product-page-selectors";
import { getBaseMedia, getVideoDuration } from "../../../helpers/video-trimming";
import Spinner from "../../../../assets/images/v3/spinner.svg";
export function ScreenV2View(props) {
    var _a, _b;
    const { isInEditViewport, timeline } = props;
    const autoJump = useAutoJump();
    const activeZoom = useActiveZoom();
    const currentStep = useActiveView();
    const prevStep = usePreviousView();
    const prevZoom = usePreviousZoom();
    const screenMedia = useScreenMedia();
    const screen = useScreen();
    const activeVariantName = useActiveVariantName();
    const isTransitioning = useViewportIsTransitioning();
    const shouldAutoStart = useShouldAutoStart();
    const shouldAutoStartRef = useRef(shouldAutoStart); // necessary in React 18 due to batched state updates
    const setIsTransitioning = useSetIsTransitioning();
    const tourialMedia = useTourialMedia();
    const setShouldAutoStart = useSetShouldAutoStart();
    const setInitialTransitionAndAutoStartState = useSetInitialTransitionAndAutoStartState();
    const { transition: t } = activeZoom;
    const cssTransition = getCssTransition({ t, isTransitioning });
    const { width: vpw, height: vph } = props.viewportDimensions;
    const setDidLoad = useSetScreenDidLoad();
    const setVideoDuration = useSetVideoDuration();
    const pageForm = usePageForm();
    const [isReloading, setIsReloading] = useState(false);
    const trimTimers = useTrimTimers();
    // DO NOT REMOVE - Safari and other browsers do not run onLoad for stored assets
    const forceImageLoad = () => {
        if (isImage) {
            const originalSrc = imageRef.current.src;
            imageRef.current.src = "";
            imageRef.current.src = originalSrc;
        }
    };
    // set initial state "on hard refresh" (i.e. when not triggered by a changeStep)
    useEffect(setInitialTransitionAndAutoStartState, [activeVariantName]);
    useEffect(() => {
        var _a;
        setDidLoad(false);
        forceImageLoad();
        (_a = videoPlayerData === null || videoPlayerData === void 0 ? void 0 : videoPlayerData.handleVideoProgress) === null || _a === void 0 ? void 0 : _a.call(videoPlayerData, 0);
    }, [isInEditViewport, currentStep === null || currentStep === void 0 ? void 0 : currentStep.pageId]);
    useEffect(() => {
        if (timeline) {
            if (pageForm === null || pageForm === void 0 ? void 0 : pageForm.formId) {
                videoPlayerData.duration ? videoPlayerData.togglePlay(false) : timeline.pause();
            }
            else if ((videoPlayerData === null || videoPlayerData === void 0 ? void 0 : videoPlayerData.progress) != 100 || !videoPlayerData.duration) {
                videoPlayerData.duration ? videoPlayerData.togglePlay(true) : timeline.play();
            }
        }
    }, [pageForm]);
    useEffect(() => {
        shouldAutoStartRef.current = shouldAutoStart;
    }, [shouldAutoStart]);
    const currentSrc = screenMedia[currentStep.pageId];
    const tourialMediaSrc = getBaseMedia(tourialMedia, currentSrc);
    const videoControls = ((_a = screen === null || screen === void 0 ? void 0 : screen.perPageVideoControls) === null || _a === void 0 ? void 0 : _a[currentStep.pageId]) || {};
    const videoRef = useRef(null);
    const imageRef = useRef(null);
    const setVideoProgress = useSetVideoProgress();
    const [videoHasPlayed, setVideoHasPlayed] = useState(false);
    const videoPlayerData = useVideoPlayer({
        videoRef,
        videoControls,
        duration: getVideoDuration(tourialMedia, currentSrc),
        timeline,
        setVideoProgress,
        setVideoDuration: duration => setVideoDuration(duration, currentSrc),
        isReloading,
        setIsReloading,
        videoHasPlayed,
        setVideoHasPlayed,
        autoJump,
    });
    const isImage = isImageFileExtension(currentSrc);
    // tourial.media must have the src and size, otherwise transforms will not work
    const srcSize = (tourialMediaSrc === null || tourialMediaSrc === void 0 ? void 0 : tourialMediaSrc.size) || { width: 0, height: 0 };
    const dimensionToGrow = getDimensionToGrow(srcSize.width, srcSize.height, activeVariantName);
    const dimensionToGrowStyle = Object.assign(Object.assign({}, ((dimensionToGrow === null || dimensionToGrow === void 0 ? void 0 : dimensionToGrow.includes("x")) && { width: "100.01%" })), ((dimensionToGrow === null || dimensionToGrow === void 0 ? void 0 : dimensionToGrow.includes("y")) && { height: "100.01%" }));
    const src = isImage ? transformImageToWebp(currentSrc) : modifyVideoFormatForSafari(currentSrc);
    const srcAsImageOrThumbnail = isImage ? src : getVideoThumbnail(src);
    const getTransform = () => {
        if (shouldAutoStart && !isTransitioning) {
            // tall or 16:9 images start from the top by default
            if (dimensionToGrow === null || dimensionToGrow === void 0 ? void 0 : dimensionToGrow.includes("x")) {
                return `translate(0px, ${(vpw * (srcSize.height / srcSize.width) - vph) / 2}px)`;
            }
            // wide images start in the middle by default
            else {
                return `translate(1px, 0px)`;
            }
        }
        return getScreenTransform({ vph, vpw, dimensionToGrow, srcSize, activeZoom });
    };
    const transform = getTransform();
    const mediaStyle = Object.assign(Object.assign(Object.assign({}, dimensionToGrowStyle), { position: "relative", transform }), cssTransition);
    const onLoad = () => {
        setTimeout(() => {
            setVideoHasPlayed(false);
            if (isInEditViewport) {
                setIsReloading(false);
            }
            if (shouldAutoStartRef.current && !isTransitioning) {
                setShouldAutoStart(false);
                setIsTransitioning(true);
            }
            setDidLoad(true);
        }, 100);
    };
    let prevSrcData;
    if (!isInEditViewport) {
        const zoom = prevZoom || activeZoom;
        const step = prevStep || currentStep;
        prevSrcData = getPrevSrcData(vph, vpw, screenMedia, tourialMedia, activeVariantName, zoom, step);
    }
    if (!src)
        return null;
    return (React.createElement(ScreenWrapperChild, { id: "screen" },
        React.createElement(React.Fragment, null,
            !isInEditViewport && (React.createElement(PrevSrcDiv, { style: Object.assign({ transform: prevSrcData.prevTransform }, prevSrcData.prevDimensionToGrowStyle) },
                React.createElement("img", { src: srcAsImageOrThumbnail, style: innerMediaStyle, draggable: false }))),
            React.createElement("div", { key: currentStep.pageId, style: mediaStyle, onTransitionEnd: () => setIsTransitioning(false) }, isImage ? (React.createElement("img", { src: src, style: innerMediaStyle, draggable: false, ref: imageRef, onLoad: onLoad })) : (React.createElement("video", Object.assign({}, defaultVideoScreenSettings, { ref: videoRef, src: src, style: innerMediaStyle, poster: getVideoThumbnail(src), onTimeUpdate: videoPlayerData.handleOnTimeUpdate, onEnded: videoPlayerData.handleOnEnded, onCanPlay: videoPlayerData.handleOnCanPlay, onLoadedData: onLoad, muted: (_b = videoControls.isMuted) !== null && _b !== void 0 ? _b : false, autoPlay: isInEditViewport ? false : !!videoControls.autoPlay })))),
            !isImage && videoRef && (!!videoControls.controls || isInEditViewport) && !(trimTimers === null || trimTimers === void 0 ? void 0 : trimTimers[src]) && (React.createElement(VideoControls, Object.assign({}, videoPlayerData))),
            !!(trimTimers === null || trimTimers === void 0 ? void 0 : trimTimers[src]) && (React.createElement(TrimSpinner, null,
                React.createElement("img", { src: Spinner }))))));
}
export const ScreenWrapperChild = styled(WrapperChild) `
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0.001deg);
`;
const PrevSrcDiv = styled.div `
  position: absolute;
  z-index: -1;
  background-color: ${newTheme.colors.white};
`;
const defaultVideoScreenSettings = {
    loop: false,
    draggable: false,
    playsInline: true,
    controls: false,
};
const innerMediaStyle = { objectFit: "cover", width: "inherit", height: "inherit" };
function getCssTransition({ t, isTransitioning }) {
    return isTransitioning
        ? {
            transitionProperty: "transform",
            transitionDuration: `${t.durationSecs}s, ${t.durationSecs}s, ${t.durationSecs}s`,
            transitionTimingFunction: "ease",
            transitionDelay: `${Math.max(t.delaySecs, 0.15)}s`,
            WebkitTransitionProperty: "transform",
            WebkitTransitionDuration: `${t.durationSecs}s, ${t.durationSecs}s, ${t.durationSecs}s`,
            WebkitTransitionTimingFunction: "ease",
            WebkitTransitionDelay: `${Math.max(t.delaySecs, 0.15)}s`,
        }
        : {};
}
export const TrimSpinner = styled.div `
  position: absolute;
`;
