import { useState, useEffect } from "react";
import { roundToHundredths } from "../../../shared/functions/helpers";
import { useViewportMode } from "../redux/selectors/redux-selectors";
import { ViewportMode } from "../types/definitions";
/** Cloudinary's videos are improperly encoded upon transformation.
    The start time and duration are not guaranteed to be what we request.
    This variable is here so that we can still auto-start videos whose encodings don't start at 0.
 */
export const CLOUDINARY_ZERO = 0.99999;
const useVideoPlayer = ({ videoRef, videoControls, duration, timeline, setVideoProgress, setVideoDuration, isReloading, setIsReloading, videoHasPlayed, setVideoHasPlayed, autoJump, }) => {
    var _a;
    const isInEditViewport = useViewportMode() === ViewportMode.EDIT;
    const [playerState, setPlayerState] = useState({
        isPlaying: false,
        progress: 0,
        // speed: 1, // add if implementing playback speed
        // volume: 0, // add if implementing volume dial
        isMuted: false,
        cloudinaryImperfection: 0,
    });
    useEffect(() => {
        setPlayerState(Object.assign(Object.assign({}, playerState), { isMuted: videoControls.isMuted }));
    }, [videoControls.isMuted]);
    const togglePlay = (manualOverride) => {
        manualOverride !== null && manualOverride !== void 0 ? manualOverride : (!playerState.isPlaying ? timeline === null || timeline === void 0 ? void 0 : timeline.play() : timeline === null || timeline === void 0 ? void 0 : timeline.pause());
        setPlayerState(Object.assign(Object.assign({}, playerState), { isPlaying: manualOverride !== null && manualOverride !== void 0 ? manualOverride : !playerState.isPlaying }));
    };
    useEffect(() => {
        if (!videoRef.current)
            return;
        if (videoControls.playbackSpeed)
            videoRef.current.playbackRate = videoControls.playbackSpeed;
        playerState.isPlaying ? videoRef.current.play() : videoRef.current.pause();
    }, [playerState.isPlaying, videoRef]);
    useEffect(() => {
        if (!videoRef.current || !videoControls.playbackSpeed)
            return;
        videoRef.current.playbackRate = videoControls.playbackSpeed;
    }, [videoControls.playbackSpeed]);
    useEffect(() => {
        if (!videoRef.current)
            return;
        const autoplay = !videoRef.current.paused &&
            videoRef.current.currentTime > CLOUDINARY_ZERO &&
            !videoRef.current.ended &&
            !playerState.isPlaying;
        if (autoplay) {
            setPlayerState(Object.assign(Object.assign({}, playerState), { isPlaying: true }));
        }
    }, [(_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.paused]);
    // Known issue: duration is Infinity until the video is fully loaded, resulting in incorrect progress percent
    // this also affects the native browser video controls
    const handleOnTimeUpdate = () => {
        const progress = (videoRef.current.currentTime / (duration - playerState.cloudinaryImperfection || videoRef.current.duration)) *
            100;
        setVideoProgress(roundToHundredths((progress / 100) * (duration - playerState.cloudinaryImperfection || videoRef.current.duration)));
        setPlayerState(Object.assign(Object.assign({}, playerState), { progress }));
    };
    const handleOnEnded = () => {
        if (videoRef.current.seekToEnd) {
            videoRef.current.seekToEnd = false;
            return;
        }
        setPlayerState(Object.assign(Object.assign({}, playerState), { isPlaying: false }));
        if (!isInEditViewport && videoControls.autoJump) {
            autoJump();
        }
    };
    const handleVideoProgress = (integerPercent) => {
        try {
            if (!videoRef.current)
                return;
            if (!duration && (videoRef.current.duration === Infinity || isNaN(videoRef.current.duration))) {
                videoRef.current.addEventListener("seeked", () => {
                    // currentTime will represent the duration here. Apply to media since it's missing, then reset.
                    // it can only be missing for legacy videos that pre-date addition of the duration field.
                    // this should only run for an un-trimmed original video. It is not subject to the cloudinary non-zero bug.
                    // once set, it cannot be overwritten as the action guards against overwriting.
                    setVideoDuration(videoRef.current.currentTime);
                    videoRef.current.currentTime = 0;
                }, { once: true });
                videoRef.current.seekToEnd = true;
                videoRef.current.currentTime = 9999;
                return;
            }
            let cloudinaryImperfection = 0;
            if (videoRef.current.duration !== duration) {
                const it = duration - videoRef.current.duration;
                if (!isNaN(it) && Math.abs(it) !== Infinity) {
                    cloudinaryImperfection = Math.abs(it);
                }
            }
            const targetTime = ((duration - cloudinaryImperfection || videoRef.current.duration) / 100) * integerPercent;
            videoRef.current.currentTime = targetTime;
            timeline === null || timeline === void 0 ? void 0 : timeline.progress(integerPercent / 100);
            setVideoProgress(roundToHundredths(videoRef.current.currentTime));
            setPlayerState(Object.assign(Object.assign({}, playerState), { cloudinaryImperfection, progress: integerPercent }));
            // After trimming, the currentTime of the video cannot be set until
            // the video has finished processing, and we have to "reload" the src
            // after it has finished processing for that to work again.
            // This is hacky, but good enough for now. Consider replacing with a Cloudinary api callback.
            if (!isReloading && isInEditViewport && !floatEq(videoRef.current.currentTime, targetTime)) {
                setIsReloading(true);
                videoRef.current.pause();
                videoRef.current.setAttribute("src", videoRef.current.src);
                videoRef.current.load();
                setVideoProgress(0);
                timeline === null || timeline === void 0 ? void 0 : timeline.progress(0);
                videoRef.current.currentTime = 0;
                setPlayerState(Object.assign(Object.assign({}, playerState), { progress: 0 }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const toggleMute = () => {
        setPlayerState(Object.assign(Object.assign({}, playerState), { isMuted: !playerState.isMuted }));
    };
    useEffect(() => {
        if (!videoRef.current)
            return;
        playerState.isMuted ? (videoRef.current.muted = true) : (videoRef.current.muted = false);
    }, [playerState.isMuted, videoRef]);
    const handleOnCanPlay = () => {
        if (isInEditViewport)
            return;
        if (videoRef.current.paused &&
            videoRef.current.currentTime < CLOUDINARY_ZERO &&
            videoControls.autoPlay &&
            !videoHasPlayed) {
            setVideoHasPlayed(true);
            // if autoPlay is on and video has not kicked off, manually attempt play()
            videoRef.current
                .play()
                .then(() => togglePlay(true))
                .catch(err => {
                // all major browsers will return a promise error if browser has rejected
                // one common reason is that the video is unmuted, so mute and attempt again
                console.log(err);
                videoRef.current.muted = true;
                // and no, unmuting after playing here is blocked
                videoRef.current
                    .play()
                    .then(() => togglePlay(true))
                    .catch(() => console.log("re-attempt failed"));
            });
        }
        // handles a video that successfully autoplayed (syncs the video controller state w/o actually forcing play())
        else if (!videoHasPlayed &&
            videoRef.current.currentTime < CLOUDINARY_ZERO &&
            !videoRef.current.paused &&
            videoControls.autoPlay) {
            setVideoHasPlayed(true);
            togglePlay(true);
        }
    };
    return Object.assign(Object.assign({}, playerState), { togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
        toggleMute,
        handleOnEnded,
        duration,
        handleOnCanPlay });
};
export function floatEq(a, b) {
    if (a === b)
        return true;
    const epsilon = 0.1;
    return Math.abs(a - b) <= epsilon;
}
export default useVideoPlayer;
