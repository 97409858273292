import React, { useEffect, useState } from "react";
import ReactPortal from "../../../helpers/react-portal";
import styled from "styled-components";
import { newTheme } from "../../styled/theme";
import TButton, { TButtonVariants } from "../../styled/t-button";
import ArrowIcon from "../../../../assets/images/v3/arrow-right-black.svg";
import { TCheckbox } from "../../styled/t-checkbox";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import { useSetShowChromeExtensionModal } from "../../../legacy/selectors";
import { useAsync } from "react-async";
import { checkExtensionInstalled } from "../sidebar/side-bar";
import { PAGE_HEADER_Z_INDEX } from "../../../types/definitions";
export default function ChromeExtensionModal({ onClose, openNewTourModal, hideCheckbox, }) {
    const [show, setShow] = useState(true);
    const setShowChromeExtensionModal = useSetShowChromeExtensionModal();
    const [extensionInstalled, setExtensionInstalled] = useState(false);
    useAsync({
        promiseFn: checkExtensionInstalled,
        onResolve: data => setExtensionInstalled(data),
    });
    useEffect(() => {
        const closeOnEscapeKey = e => (e.key === "Escape" ? onClose() : null);
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [onClose]);
    return (React.createElement(ReactPortal, { wrapperId: "tourial-portal-modal-container" },
        React.createElement(Overlay, { id: "t-overlay", onMouseDown: e => {
                if (document.elementFromPoint(e.clientX, e.clientY).id === "t-overlay")
                    onClose();
            } },
            React.createElement(ModalContainer, null,
                React.createElement(Header, null,
                    React.createElement("span", { onClick: onClose, style: { cursor: "pointer" } }, "\u2715")),
                React.createElement("div", { style: { fontWeight: "bold" } }, "Get started making demos with Tourial."),
                React.createElement("img", { src: "https://res.cloudinary.com/dim9has1z/image/upload/v1715105795/gsplfviv8vaygackv53r.png" }),
                React.createElement(StepContainer, null,
                    React.createElement(NumberCircle, { extensionInstalled: extensionInstalled }, extensionInstalled ? "\u2713" : "1"),
                    React.createElement(StepInstruction, null,
                        "Install the Chrome Extension from the",
                        " ",
                        React.createElement("a", { target: "_blank", rel: "noreferrer", href: "https://chrome.google.com/webstore/detail/tourial-extension/bgnjlhcgomfmcohochohgmplhfeipjob/related?hl=en&authuser=1", style: { textDecoration: "underline", color: newTheme.colors.blue500 } }, "Chrome web store"),
                        ".")),
                React.createElement(StepContainer, null,
                    React.createElement(NumberCircle, null, "2"),
                    React.createElement(StepInstruction, null, "Visit your platform.")),
                React.createElement(StepContainer, null,
                    React.createElement(NumberCircle, null, "3"),
                    React.createElement(StepInstruction, null, "Choose HTML or Media to begin capturing your product tour.")),
                React.createElement(StepContainer, null,
                    React.createElement(NumberCircle, null, "4"),
                    React.createElement(StepInstruction, null, `Click the Tourial Chrome Extension when you're finished to import your screens.`)),
                !hideCheckbox && (React.createElement(StepContainer, null,
                    React.createElement(TCheckbox, { checked: !show, onChange: e => {
                            TourialApiCalls.Users.setShowChromeExtensionModal({ show: !e })
                                .then(() => {
                                // update local state and login reducer
                                setShow(!e);
                                setShowChromeExtensionModal(!e);
                            })
                                .catch(() => {
                                console.error("failed to update showChromeExtensionModal");
                            });
                        } }),
                    React.createElement(StepInstruction, null, "Do not show this popup again."))),
                React.createElement(TButton, { variant: TButtonVariants.OUTLINE, size: "small", onClick: () => {
                        openNewTourModal();
                        onClose();
                    }, icon: ArrowIcon, iconPlacement: "right", style: { width: "100%" }, id: "_test_manually_upload_screenshots_button" }, "\u00A0 Manually upload screenshots to media tour \u00A0 \u00A0")))));
}
const StepContainer = styled.div `
  display: flex;
  flex-wrap: nowrap;
  color: ${newTheme.colors.grey700};
  gap: 8px;
  width: 100%;
`;
const NumberCircle = styled.div `
  background: ${({ extensionInstalled }) => (extensionInstalled ? newTheme.colors.green600 : "black")};
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  text-align: center;
  line-height: 22px;
`;
const Overlay = styled.div `
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${PAGE_HEADER_Z_INDEX + 100};
  display: flex;
  justify-content: end;
  overflow-y: auto;
`;
const ModalContainer = styled.div `
  background-color: ${newTheme.colors.white};
  box-shadow: ${newTheme.shadows.outside80};
  margin: 18px;
  width: 333px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  font: ${newTheme.fonts.medium};
  border-radius: 4px;
`;
const Header = styled.div `
  margin-top: -8px;
  width: 100%;
  font: ${newTheme.fonts.header};
  display: flex;
  justify-content: end;
  color: ${newTheme.colors.grey800};
`;
const StepInstruction = styled.div `
  flex: 1;
  line-height: 22px;
`;
