import React, { useEffect, useRef, useState } from "react";
import { useRefDimensions } from "../../../hooks/viewport-hooks";
import styled from "styled-components";
import { usePrefetchCloudinaryUrls } from "../../../hooks/use-prefetch-cloudinary-urls";
import { newTheme } from "../../styled/theme";
import { useTools } from "../../../redux/selectors/redux-selectors";
import { RenderTools } from "../render-tools";
import { ScreenV2View } from "../../tools/screen/screen-v2-view";
import { PageFormV3, TourFormV3 } from "../fetch-and-render-form-v3";
import TourialWatermark from "../../../legacy/components/tool-views/watermark-view";
import { ViewportHeader } from "./viewport-header";
import useTimeline from "../../../hooks/use-timeline-hook";
import { usePostLoadEvent, usePostSessionEndOnUnload, usePostViewEvent } from "../../../hooks/live-hooks";
import { useDemoCenter } from "../../../redux/selectors/demo-center-selectors";
import { useDetectMetaTag } from "../../../hooks/use-detect-meta-tag";
import TimelineProgressBar from "../timeline-progress-bar";
// import { ensureHttpsInUrl, openUrlInNewTab } from "../../../../../shared/functions/helpers";
export function LiveViewportV3() {
    const viewportRef = useRef(null);
    const viewportDimensions = useRefDimensions(viewportRef);
    const tools = useTools();
    usePrefetchCloudinaryUrls();
    usePostLoadEvent();
    usePostViewEvent();
    usePostSessionEndOnUnload();
    useDetectMetaTag();
    const demoCenter = useDemoCenter();
    const baseViewProps = {
        viewportDimensions,
        disableInteractions: false,
        disableAnimations: false,
        disableEventTracking: false,
        isInEditViewport: false,
    };
    const [viewportHeader, setViewportHeader] = useState(false);
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        setViewportHeader(!!urlSearchParams.get("viewportHeader"));
        const handleDemoChatbotMessages = data => {
            var _a;
            if (((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.type) === "OPEN_SCHEDULER") {
                // TODO: if a scheduler exists, open in the tourial iframe
                // openUrlInNewTab(ensureHttpsInUrl("https://calendly.com/oscar-aos/oscar-test"));
            }
        };
        window.addEventListener("message", handleDemoChatbotMessages);
        return () => {
            window.removeEventListener("message", handleDemoChatbotMessages);
        };
    }, []);
    const timeline = useTimeline({ baseViewProps });
    return (React.createElement(React.Fragment, null,
        viewportHeader && React.createElement(ViewportHeader, null),
        React.createElement(Div, { id: "tourial-live-viewport", className: "tourial-viewport", ref: viewportRef, 
            // onDragOver={e => e.preventDefault()}
            style: { height: viewportDimensions.height } },
            React.createElement(ScreenV2View, Object.assign({}, Object.assign(Object.assign({}, baseViewProps), { toolData: tools.screen, timeline }))),
            React.createElement(RenderTools, { baseViewProps: baseViewProps }),
            React.createElement(TimelineProgressBar, { timeline: timeline }),
            React.createElement(TourFormV3, { baseViewProps: baseViewProps }),
            !demoCenter && React.createElement(PageFormV3, null),
            React.createElement(TourialWatermark, { key: "tourial_watermark", viewportDimensions: viewportDimensions }))));
}
const Div = styled.div `
  left: 0;
  right: 0;
  background-color: ${newTheme.colors.white};
  position: absolute;
  overflow: hidden;
`;
