import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useIsAutomatedScreen, useTourialAccountId } from "../../redux/selectors/redux-selectors";
import { useFetchThemePrimaryColor } from "../../hooks/use-fetch-theme";
import { hexToRGB } from "../../helpers/misc";
export default function TimelineProgressBar({ timeline }) {
    const accountId = useTourialAccountId();
    const primaryColor = useFetchThemePrimaryColor(accountId);
    const isAutomated = useIsAutomatedScreen();
    const [colorHex, setColorHex] = useState("");
    useEffect(() => {
        const { r, g, b } = hexToRGB(primaryColor);
        setColorHex(`rgba(${r},${g},${b},.5)`);
    }, [primaryColor]);
    if (!isAutomated || !timeline)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { style: {
                background: `linear-gradient(white, white), ${colorHex}`,
                backgroundBlendMode: "multiply",
            } },
            React.createElement(Progress, { style: {
                    width: `${timeline.progressPercentage * 100}%`,
                    backgroundColor: primaryColor,
                } })),
        timeline.progressTime > 1 && React.createElement(Skipper, { onClick: handleSkip })));
    function handleSkip() {
        timeline.progress(100);
    }
}
const Skipper = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
`;
const Container = styled.div `
  pointer-events: none;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: min(8px, 1.5%);
`;
const Progress = styled.div `
  height: 100%;
`;
